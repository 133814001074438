import React, { useState, useEffect } from 'react'
import Layout from '../../components/Layout'

export const LinksPage = () => {

  const [links, setLinks] = useState([]);

  useEffect(() => {
    fetch(`https://services.vitspot.com/links/`)
    .then(response => response.json())
    .then(resultData => {
      setLinks(resultData)
    })
  }, [])

  return (
    <Layout>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="section">
                <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
                  Links
                </h2>
                {
                  links.map(({ title, url, priority }) => (
                    <>
                      <a href={url} className={`button is-medium is-fullwidth mb-4 is-multiline ${priority === "TRUE" ? "is-dark" : ""}`}>
                        <p>{title}</p>
                      </a>
                    </>
                  ))
                }
                {
                  links.length === 0 && (
                    <p>Loading...</p>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default LinksPage